<template>
    <el-container>
        <el-header height="45px" style="padding: 5px;">
            <el-button icon="el-icon-circle-plus" plain type="success" @click="editAccount('add')">新增</el-button>
            <el-button icon="el-icon-edit-outline" type="primary" @click="editAccount('edit')">修改</el-button>
            <el-button icon="el-icon-delete-solid" type="danger" @click="deleteAccount">删除</el-button>
            <el-button @click="getAuthUrl">授权</el-button>
            <el-button @click="getAccounts()" icon="el-icon-refresh" :loading="loading"></el-button>
        </el-header>
        <el-main>
            <el-table :data="accounts" ref="accountTable" @row-click="changeSelect" style="width: 80%;">
                <el-table-column type="selection" width="43" align="center" />
                <el-table-column prop="PlatformName" label="快递平台" />
                <el-table-column prop="ExpressName" label="账号名称" />
                <el-table-column prop="Enabled" label="启用状态">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.Enabled" @change="changeRow(scope.row)" />
                    </template>
                </el-table-column>
                <el-table-column prop="Enabled" label="默认">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.IsDefault" @change="changeRow(scope.row)" />
                    </template>
                </el-table-column>
                <el-table-column prop="Sort" label="排序" />
                <el-table-column prop="DockCode" label="关联码" />
                <el-table-column label="发件人">
                    <template slot-scope="{ row }">
                        {{ row.SenderName }}（{{ row.SenderMobile }}）
                    </template>
                </el-table-column>
                <el-table-column prop="AuthStatus" label="有效状态">
                    <template slot-scope="scope">
                        <i v-if="scope.row.AuthStatus" class="el-icon-success" style="color:#67C23A;"></i>
                        <i v-else class="el-icon-error" style="color:#909399;"></i>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <el-footer height="45px">
            <el-pagination background :total="total" :page-size="15" layout="prev, pager, next,total"
                @current-change="getAccounts" />
        </el-footer>
        <el-dialog v-dialog-drag title="物流账号配置" width="500px" :visible="editVisible" :close-on-click-modal="false"
            @close="editVisible = false">
            <el-form label-width="150px" :model="editForm" label-suffix="：" ref="editForm" :rules="editRules">
                <el-form-item label="快递平台" prop="platform">
                    <el-select placeholder="请选择快递平台" v-model="editForm.platform" value-key="Name"
                        :disabled="editForm.ID > 0">
                        <el-option v-for="item in platforms" :key="item.ID" :label="item.Name" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商家账号名称" prop="ExpressName">
                    <el-input v-model="editForm.ExpressName" />
                </el-form-item>
                <el-form-item label="排序">
                    <el-input-number v-model="editForm.Sort" :min="0" :max="99" />
                </el-form-item>
                <el-form-item label="关联码">
                    <el-input v-model="editForm.DockCode" />
                </el-form-item>
                <el-form-item label="发件人姓名" prop="SenderName">
                    <el-input v-model="editForm.SenderName" />
                </el-form-item>
                <el-form-item label="发件人手机号" prop="SenderMobile">
                    <el-input v-model="editForm.SenderMobile" />
                </el-form-item>
                <el-form-item label="启用">
                    <el-switch v-model="editForm.Enabled">
                    </el-switch>
                </el-form-item>
                <el-form-item label="默认">
                    <el-switch v-model="editForm.IsDefault">
                    </el-switch>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveAccount">确 定</el-button>
            </div>
        </el-dialog>
    </el-container>
</template>
<script>
import { submit } from "@/api/base";

export default {
    name: 'expressAccount',
    data() {
        return {
            loading: false,
            editVisible: false,
            platforms: [],
            accounts: [],
            total: 0,
            editForm: {
                platform: null,
                PlatformID: null,
                ExpressName: "",
                Sort: 0,
                DockCode: "",
                Enabled: true,
                IsDefault: false
            },
            editRules: {
                platform: [
                    { required: true, message: '请选择快递平台', trigger: 'change' }
                ],
                ExpressName: [
                    { required: true, message: '请输入物流账号名称', trigger: 'blur' }
                ],
                SenderName: [
                    { required: true, message: '请输入发件人姓名', trigger: 'blur' }
                ],
                SenderMobile: [
                    { required: true, message: '请输入发件人手机号', trigger: 'blur' },
                    { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: "手机号格式错误", trigger: "blur" },
                ]
            }
        }
    },
    async mounted() {
        await this.getAccounts();
    },
    methods: {
        async getAccounts(page = 1) {
            this.loading = true;
            const { data } = await submit("/api/express/getAccounts", {
                currentPage: page,
                pageSize: 15
            });
            this.accounts = data.datas;
            this.total = data.total;
            this.loading = false;
        },
        changeSelect(row, column) {
            if (column.property == "Enabled" || column.property == 'IsDefault') {
                return;
            }
            this.$refs.accountTable.toggleRowSelection(row);
        },
        async editAccount(type) {
            if (this.platforms.length == 0) {
                await this.getPlatforms();
            }
            if (this.platforms.length == 0) {
                this.$baseMessage("未找到可操作物流平台，请联系技术处理", "error");
                return;
            }
            this.editForm = null;
            switch (type) {
                case "add":
                    this.editForm = {
                        platform: null,
                        PlatformID: null,
                        ExpressName: "",
                        Sort: 0,
                        DockCode: "",
                        Enabled: true,
                        IsDefault: false
                    };
                    break;
                case "edit":
                    {
                        let selection = JSON.parse(JSON.stringify(this.$refs.accountTable.selection));
                        if (selection.length == 0) {
                            this.$baseMessage("请选择数据", "error");
                            return;
                        }
                        if (selection.length > 1) {
                            this.$baseMessage("只能选择一项", "error");
                            return;
                        }
                        this.editForm = selection[0];
                        this.editForm.platform = this.platforms.filter(t => t.ID == this.editForm.PlatformID)[0];
                    }
                    break;
            }
            this.editVisible = true;
        },
        async deleteAccount() {
            let selection = JSON.parse(JSON.stringify(this.$refs.accountTable.selection));
            if (selection.length == 0) {
                this.$baseMessage("请选择数据", "error");
                return;
            }
            await submit("/api/express/deleteAccount?ids=" + selection.map(t => t.ID).join(','));
            this.$baseMessage(`删除成功`, "success");
            this.getAccounts();
        },
        async changeRow(row) {
            await submit("/api/express/saveAccount", row);
            this.getAccounts();
        },
        async saveAccount() {
            this.$refs.editForm.validate(async (valid) => {
                if (valid) {
                    this.editForm.PlatformID = this.editForm.platform.ID;
                    await this.changeRow(this.editForm);
                    this.editVisible = false;
                    this.$baseMessage(`${this.editForm.ID > 0 ? '修改' : '新增'}成功`, "success");
                }
            });
        },
        async getAuthUrl() {
            let selection = JSON.parse(JSON.stringify(this.$refs.accountTable.selection));
            if (selection.length == 0) {
                this.$baseMessage("请选择数据", "error");
                return;
            }
            if (selection.length > 1) {
                this.$baseMessage("只能选择一项", "error");
                return;
            }
            if (!selection[0].Enabled) {
                this.$baseMessage("账号未启用，无法操作", "error");
                return;
            }
            const res = await submit('/api/express/getAuthUrl?id=' + selection[0].ID);
            if (res.data) {
                window.open(res.data, "_blank");
            }
            else {
                this.$baseMessage(res.msg, "error");
            }
        },
        async getPlatforms() {
            const { data } = await submit("/api/express/getPlatforms");
            this.platforms = data;
        }
    }
}
</script>
<style lang="scss" scoped>
.el-input {
    max-width: 240px;
}

.el-form-item {
    margin-bottom: 10px !important;
}
</style>